const admin = [
  // {
  //   text: '数据统计',
  //   icon: 'iconfont icon-shouye',
  //   is_show: true,
  //   child: [
  //     {
  //       text: '数据概括',
  //       path: '/home',
  //       show: true
  //     }
  //   ]
  // },

  // {
  //   text: '资料管理',
  //   icon: 'iconfont icon-shangjia',
  //   is_show: true,
  //   child: [
  //     {
  //       text: '网站信息',
  //       path: '/web',
  //       show: true
  //     },
  //     // {
  //     //   text: '轮播图管理',
  //     //   path: '/banner',
  //     //   show: true
  //     // },
  //   ]
  // },
  {
    text: '业务管理',
    icon: 'iconfont icon-order',
    is_show: true,
    child: [
      {
        text: '客户管理',
        path: '/user',
        show: true
      },
      {
        text: '订单管理',
        path: '/order',
        show: true
      },
      {
        text: '最近金价管理',
        path: '/price',
        show: true
      },
      // {
      //   text: '客户资金记录',
      //   path: '/record',
      //   show: true
      // },
      {
        text: '已结订单',
        path: '/close',
        show: true
      },
    ]
  },
]
module.exports = {
  admin: admin,
}